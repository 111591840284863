jQuery(function($){
  	var menu = new MmenuLight(
			document.querySelector( '#menu' ),
			'all'
		);

		var navigator = menu.navigation({
			// selectedClass: 'Selected',
			// slidingSubmenus: true,
			// theme: 'dark',
			// title: 'Menu'
		});

		var drawer = menu.offcanvas({
			position: 'left'
		});

		//	Open the menu.

		// let Mmenu = document.querySelector( 'a[href="#menu"]' );
		var Mmenu = $( 'a[href="#menu"]' );

		$(Mmenu).click(function(){
			drawer.open();
			Mmenu.addClass('mystyle');
		});
	

	var headerHeight = 0;
	$(document).ready(function() {

		//-----HeaderPadding
		headerHeight = $('#headerSection').outerHeight(true);
	    // console.log('headerHeight', headerHeight);
	    // console.log("Header Height", headerHeight);
	    $('main').css('paddingTop', headerHeight);
		
	    $(window).scroll(function() {

			if ($(this).scrollTop() > 150){

			  $('header').addClass("shadowBox");

			} else {

			  $('header').removeClass("shadowBox");

			}
		});

		$('.open-popup-link').magnificPopup({
			type: 'inline',
			midClick: true,
			mainClass: 'mfp-fade'
		});

		$('.open-video-link').magnificPopup({
		  type: 'iframe',
		  midClick: true,
		  mainClass: 'mfp-fade'
		});

		$('.needsScrollTo').click(function(event) {
			event.preventDefault();
			// console.log('clicked');
			$('body').scrollTo($(this).data('target'), 500, { offset: -1 * (headerHeight + 60) });
		})

		$(document).ready(function() {
			AOS.init();
		})
		const myVivus = new Vivus('homeSVG');
		myVivus.play(1);
	});

});


